<template>
    <defaultSec :title="'< 返回'" :returnState="true">
        <el-form :model="form" label-width="120px">
            <el-form-item label="字典名称" prop="dicName" :required="true">
                <el-input v-model="form.dicName" placeholder="字典名称"></el-input>
            </el-form-item>
            <el-form-item label="字典sql" prop="dicUpdateSql" :required="true">
                <el-input v-model="form.dicUpdateSql" placeholder="字典sql"></el-input>
            </el-form-item>
            <el-form-item label="字典类型" prop="dicType" :required="true">
                <el-select v-model="form.dicType" filterable clearable
                           placeholder="字典类型">
                    <el-option v-for="item in dicTypeList"
                               :key="item.value"
                               :label="item.name"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="字典数值类型" prop="dicKeyType" :required="true">
                <el-select v-model="form.dicKeyType" filterable clearable placeholder="字典数值类型">
                    <el-option v-for="item in dicKeyTypeList"
                               :key="item.value"
                               :label="item.name"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="字典id" prop="id" v-show="false">
                <el-input v-model="form.id" placeholder="字典id"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button-group>
                    <el-button type="warning " size="mini"
                               @click="goDetail({id:0,dicId:form.id})">添加字典项

                    </el-button>
                    <el-button type="primary" @click="save" size="mini" class="header-btn">
                        保存

                    </el-button>
                </el-button-group>
            </el-form-item>
        </el-form>
        <el-table :data="itemList" border size="mini">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column label="标识" prop="id" width="80"></el-table-column>
            <el-table-column label="字典id" prop="dicId" width="80"></el-table-column>
            <el-table-column label="key" prop="dicKey" width="190"></el-table-column>
            <el-table-column label="value" prop="dicValue" width="150"></el-table-column>
            <el-table-column label="父key" prop="parentDicKey" width="150"></el-table-column>
            <el-table-column label="排序" prop="sort" width="130"></el-table-column>
            <el-table-column label="状态" prop="statusName"></el-table-column>
            <el-table-column label="操作" fixed="right" width="140">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button type="primary" size="mini"
                                   @click="goDetail(scope.row)">详情
                        </el-button>
                        <el-button type="warning"  @click="deleteOption(scope.row.id)" size="mini" class="header-btn">
                            删除
                        </el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    export default {
        data() {
            return {
                dicTypeList: [],
                dicKeyTypeList: [],
                itemList: [],
                form: {
                    id: this.$route.params.id || 0,
                    dicName: '',
                    dicKeyType: 2,
                    createTime: '',
                    updateTime: '',
                    dicType: 1,
                    dicUpdateSql: ''
                },
                total: 0
            }
        },
        components: {
            defaultSec, tablePagination
        },
        mounted(){
            this.initDicTypeList()
            this.initDicKeyTypeList()
            if (this.form.id > 0) {
                this.getDetail()
            }
        },
        methods: {
            getDetail() {
                this.$apiGet('lechun-cms/scrmOption/getDic', {id: this.$route.params.id}).then(res => {
                    this.form = res
                    this.itemList = res.dicOptionList
                })
            },
            initDicTypeList(){
                this.$apiGet('lechun-cms/scrmOption/getDicTypeList', {}).then(res => {
                    console.log(res)
                    this.dicTypeList = res
                })
            },
            initDicKeyTypeList(){
                this.$apiGet('lechun-cms/scrmOption/getDicKeyTypeList', {}).then(res => {
                    console.log(res)
                    this.dicKeyTypeList = res
                })
            },
            goDetail(row){
                this.$router.push({
                    path: '/optionItem/:id/:dicId',
                    name: 'optionItem',
                    params: {id: row.id, dicId: row.dicId}
                });
            },
            save(){
                if (this.form.dicName == '') {
                    this.$message({
                        showClose: true,
                        message: '名必填',
                        type: 'error'
                    });
                    return;
                }
                this.$apiPost('lechun-cms/scrmOption/saveDic', this.form).then(res => {
                    console.log(res)
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                })
            },
            deleteOption(id){
                this.$confirm('删除后不可撤销，确定删除吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-cms/scrmOption/deleteDetail', {id: id}).then(res => {
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        });
                        console.log(res)
                        this.getDetail()
                    })
                }).catch(() => {
                });
            }
        }
    }
</script>


<style scoped>
    .expand-title {
        width: 70px;
        color: #444548;
        line-height: 30px;
        text-align: right;
        margin-right: 10px;
        font-weight: 600;
    }

    .expand-flex {
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
</style>